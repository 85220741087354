import {
  ApiBooleanResult,
  ApiResult,
  ContractSymbol,
  WatchSymbol,
  WatchListName,
  WatchGroup, PriceCls, Option, WatchOption, CryptoSymbol, WatchCrypto
} from '@/services/data'
import ServiceBase from '@/services/serviceBase'

export interface SharedStatus {
  UserId: number;
  Name: string;
  LoginId: string;
  SimpleName: string;
  IsReadOnly: boolean;
}

export default {
  async getWatchListNamesAsync (): Promise<ApiResult<WatchListName[]>> {
    return await ServiceBase.tryPostResult<WatchListName[]>('/watchlist/getWatchListNames')
  },
  async getWatchListsAsync (watchListId?: number, name?: string): Promise<ApiResult<WatchGroup[]>> {
    const res = await ServiceBase.tryPostResult<WatchGroup[]>('/watchlist/getWatchLists', {
      WatchListId: watchListId,
      Name: name
    })
    if (res.Result) {
      res.Result.forEach(p => {
        p.Symbols.forEach(q => {
          q.Price = new PriceCls(q.Price)
          q.Symbol.Detail = q.Symbol.Detail ? JSON.parse(q.Symbol.Detail) : null
        })
        p.Options.forEach(q => {
          q.Price = new PriceCls(q.Price)
        })
      })
    }
    return res
  },
  async renameWatchListAsync (WatchListId: number, NewName: string): Promise<ApiBooleanResult> {
    return await ServiceBase.tryPostBooleanResult('/watchlist/renameWatchList', {
      WatchListId,
      NewName
    })
  },
  async shareWatchListAsync (WatchListId: number, ToUserId: number, IsReadyOnly: boolean): Promise<ApiResult<WatchListName>> {
    return await ServiceBase.tryPostResult<WatchListName>('/watchlist/shareWatchList', {
      WatchListId,
      IsReadyOnly,
      ToUserId
    })
  },
  async unShareWatchListAsync (WatchListId: number, ToUserId: number): Promise<ApiResult<WatchListName>> {
    return await ServiceBase.tryPostResult<WatchListName>('/watchlist/unShareWatchList', {
      WatchListId,
      ToUserId
    })
  },
  async duplicateWatchListAsync (WatchListId: number): Promise<ApiResult<WatchListName>> {
    return await ServiceBase.tryPostResult<WatchListName>('/watchlist/duplicateWatchList', {
      WatchListId,
      ToUserId: 0
    })
  },
  async deleteWatchListAsync (WatchListId: number): Promise<ApiBooleanResult> {
    return await ServiceBase.tryPostBooleanResult('/watchlist/deleteWatchList', {
      WatchListId
    })
  },
  async addWatchListAsync (WatchListName: string): Promise<ApiResult<WatchListName>> {
    return await ServiceBase.tryPostResult<WatchListName>('/watchlist/addWatchList', {
      WatchListName
    })
  },
  async addSymbolToWatchListAsync (WatchListId: number, Symbol: ContractSymbol): Promise<ApiResult<WatchSymbol>> {
    const res = await ServiceBase.tryPostResult<WatchSymbol>('/watchlist/addSymbolToWatchList', {
      WatchListId,
      Symbol
    })
    if (res.Result) {
      res.Result.Symbol.Detail = res.Result.Symbol.Detail ? JSON.parse(res.Result.Symbol.Detail) : null
    }
    return res
  },
  async deleteSymbolFromWatchListAsync (WatchListId: number, SymbolId: number): Promise<ApiBooleanResult> {
    return await ServiceBase.tryPostBooleanResult('/watchlist/deleteSymbolFromWatchList', {
      WatchListId,
      SymbolId
    })
  },
  async deleteOptionFromWatchListAsync (WatchListId: number, OptionId: number): Promise<ApiBooleanResult> {
    return await ServiceBase.tryPostBooleanResult('/watchlist/deleteOptionFromWatchList', {
      WatchListId,
      OptionId
    })
  },
  async addCryptoToWatchListAsync (WatchListId: number, Symbol: CryptoSymbol): Promise<ApiResult<WatchCrypto>> {
    return await ServiceBase.tryPostResult<WatchCrypto>('/watchlist/addCryptoWatchList', {
      WatchListId,
      Symbol
    })
  },
  async deleteCryptoFromWatchListAsync (WatchListId: number, CryptoId: number): Promise<ApiBooleanResult> {
    return await ServiceBase.tryPostBooleanResult('/watchlist/deleteCryptoFromWatchList', {
      WatchListId,
      CryptoId
    })
  },
  async updateRankAsync (Ranks: { WatchListId: number; Rank: number }[]) {
    return await ServiceBase.tryPostBooleanResult('/watchlist/updateRank', Ranks)
  },
  async updateSymbolRankAsync (WatchListId: number, Ranks: { SymbolId: number; Rank: number }[]) {
    return await ServiceBase.tryPostBooleanResult('/watchlist/updateSymbolRank', {
      WatchListId,
      Ranks
    })
  },
  async saveKeysAsync (WatchListId: number, Keys: string[]) {
    return await ServiceBase.tryPostBooleanResult('/watchlist/saveKeys', {
      WatchListId,
      Keys
    })
  },
  async getSharedUsersAsync (WatchListId: number) {
    return await ServiceBase.tryPostResult<SharedStatus[]>('/watchlist/getSharedUsers', {
      WatchListId
    })
  },
  async changeShareStatusAsync (WatchListId: number, UserId: number, IsReadOnly: boolean) {
    return await ServiceBase.tryPostResult<SharedStatus[]>('/watchlist/changeShareStatus', {
      WatchListId,
      UserId,
      IsReadOnly
    })
  },
  async addOptionToWatchListAsync (WatchListId: number, Symbol: ContractSymbol, Option: Option): Promise<ApiResult<WatchOption>> {
    return await ServiceBase.tryPostResult<WatchOption>('/watchlist/addOptionToWatchList', {
      WatchListId,
      Symbol,
      Option
    })
  },
  async getDownloadStatusAsync (symbolId: number[]) {
    return await ServiceBase.tryPostResult<{
      SymbolId: number;
      IsDownloading: boolean;
    }[]>('/watchlist/getDownloadStatus', { SymbolId: symbolId })
  }
}
