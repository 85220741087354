import { ApiBooleanResult, ApiResult, ContractSymbol, OtmRange, StockPrice } from '@/services/data'
import ServiceBase from '@/services/serviceBase'

export interface FreebieOpt extends Record<string, number | string> {
  Uid: number;
  OptionId: number;
  Time: string;
  CallPut: string;
  Expiration: string;
  Strike: number;
  Otm: number;
  OptionPrice: number;
  StockPrice: number;
  Target: number;
  State: string;
}

export interface FreebieOptionRes {
  Percentile: number;
  MinPremiumValue: number;
  MinPremiumPercent: number;
  SymbolOpts: FreebieItem[];
}

export interface FreebieItem {
  SymbolId: number;
  SymbolName: string;
  Range: OtmRange;
  Gmt: string;
  Options: FreebieOpt[];
}

export interface StockFreebie {
  Gmt?: string;
  StockPrice: StockPrice;
  FreebieRange: OtmRange;
  DownloadRange: OtmRange;
  CallOptions: FreebieOpt[];
  PutOptions: FreebieOpt[];
}

export interface HistoryDataItem{
  DataPoints: number;
  FirstUpdate: string;
  LastUpdate: string;
  Range: OtmRange[];
  Symbol: ContractSymbol;
  IsDownloading: boolean;
  ExpirationCount: number;
}

export default {
  async updateRange (Range: OtmRange): Promise<ApiBooleanResult> {
    return await ServiceBase.tryPostBooleanResult('/freebie/updateRange', Range)
  },
  async getOtmRanges (SymbolId?: number): Promise<ApiResult<OtmRange[]>> {
    return await ServiceBase.tryPostResult<OtmRange[]>('/freebie/getOtmRanges', { SymbolId })
  },
  async getOptions (DetectedOnly = false): Promise<ApiResult<FreebieOptionRes>> {
    return await ServiceBase.tryPostResult<FreebieOptionRes>('/freebie/getOptions', { DetectedOnly })
  },
  async getDetectedFreebies (): Promise<ApiResult<FreebieOptionRes>> {
    return await ServiceBase.tryPostResult<FreebieOptionRes>('/freebie/getDetectedFreebies')
  },
  async getDownloadHistoryAsync (): Promise<ApiResult<HistoryDataItem[]>> {
    return await ServiceBase.tryPostResult<HistoryDataItem[]>('/freebie/getDownHistory')
  },
  async downloadLog (symbol: string, start: string, end: string) {
    return await ServiceBase.tryDownload(`/freebie/downloadLogs/${symbol}?start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`)
  }
}
